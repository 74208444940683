import { Button, Image } from 'antd';
import { ORDER_API_STATUS_TEXT } from '../types/enums';

const getFilterClass = (activeFilter, filter) =>
	activeFilter === filter ? 'active-filter filter' : 'inactive-filter filter';
const getFilterButtonText = (meta, filter) => {
	if (filter === 'All') {
		return meta && meta['ALL'] ? meta['ALL'] : 0;
	} else {
		return meta[filter] ? meta[filter] : 0;
	}
};
const getInactiveImage = filter => {
	switch (filter) {
		case ORDER_API_STATUS_TEXT.ALL:
			return '/icons/coolicon.png';
		case ORDER_API_STATUS_TEXT.BACKORDERED:
			return '/icons/filter-download-inactive.png';
		case ORDER_API_STATUS_TEXT.CANCELLED:
			return '/icons/filter-stop-inactive.png';
		case ORDER_API_STATUS_TEXT.IN_PROGRESS:
			return '/icons/filter-reorder-inactive.png';
		case ORDER_API_STATUS_TEXT.SHIPPED:
			return '/icons/filter-deliver-inactive.png';
		case ORDER_API_STATUS_TEXT.SUBMITTED:
			return '/icons/filter-pack-inactive.png';
		case ORDER_API_STATUS_TEXT.COMPLETE:
			return '/icons/filter-done-inactive.png';
		default:
			return '/icons/coolicon.png';
	}
};
const getActiveImage = filter => {
	switch (filter) {
		case ORDER_API_STATUS_TEXT.ALL:
			return '/icons/filter-all.png';
		case ORDER_API_STATUS_TEXT.BACKORDERED:
			return '/icons/filter-download.png';
		case ORDER_API_STATUS_TEXT.CANCELLED:
			return '/icons/filter-stop.png';
		case ORDER_API_STATUS_TEXT.IN_PROGRESS:
			return '/icons/filter-reorder.png';
		case ORDER_API_STATUS_TEXT.SHIPPED:
			return '/icons/filter-deliver.png';
		case ORDER_API_STATUS_TEXT.SUBMITTED:
			return '/icons/filter-pack.png';
		case ORDER_API_STATUS_TEXT.COMPLETE:
			return '/icons/filter-done.png';
		default:
			return '/icons/filter-all.png';
	}
};
const getFilterIcon = (activeFilter, filter) => {
	if (activeFilter === filter) {
		return getInactiveImage(filter);
	} else {
		return getActiveImage(filter);
	}
};

const LineFilterButton = ({ onClick, activeFilter, filter, meta }) => (
	<Button
		icon={
			<Image
				className="filter-button-icon"
				preview={false}
				src={getFilterIcon(activeFilter, filter)}
			/>
		}
		onClick={onClick}
		className={getFilterClass(activeFilter, filter)}>
		&nbsp;&nbsp;(
		{getFilterButtonText(meta, filter)})
	</Button>
);
export default LineFilterButton;
