import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import '../styles/information_go.scss';
import { BASE_TITLE, returnSvg } from '../utils/utility';
import { touchStart, touchEnd, touchMove } from '../utils/touchfunctionality';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';

function Onboarding8() {
	const [pageIndex] = useState(7);
	const history = useHistory();
	const { t } = useTranslation();
	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Onboarding - One Last Thing';
	}, []);
	return (
		<div
			className="onboarding-container text-overflow-auto"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, 'onboarding8', 'onboarding7')}>
			<div className="img-container img-container8">
				<Image preview={false} src="/media/svg/onboarding8.svg" />
			</div>
			<div className="description">
				<div className="title-text">{t('oneLastThing')}</div>
				<div className="body-text">{t('helpImproveExperience')}</div>
			</div>
			<div className="pagination-list">
				{[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) =>
					returnSvg(Boolean(pageIndex === index)),
				)}
			</div>
			<div className="button-container button-container-flex">
				<div
					className="button skip-all"
					onTouchEnd={() => {
						localStorage.setItem('onboarded', '1');
						history.push('/accounts');
					}}>
					{t('mayBeLater')}
				</div>
				<div
					className="button next"
					onClick={() => {
						localStorage.setItem('onboarded', '1');
						history.push('/accounts');
					}}>
					{t('yesIdLove')}
				</div>
			</div>
		</div>
	);
}

export default Onboarding8;
