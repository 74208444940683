import { Card, message, Row, Col, Typography, Image, Breadcrumb } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import './../styles/ProductSubstitution.scss';
import { Footer } from '../elements/Footer';
import { TopBar } from '../elements/TopBar';
import { getSubstituteProducts } from '../services/ProductSubstitution';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../elements/CircularProgressBar';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import {
	getProductImage,
	getProductImagesToken,
} from '../services/ProductImages';
import LineItem from '../elements/LineItem';
import { BASE_TITLE, getFsrCustomerCareNumber } from '../utils/utility';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const { Item } = Breadcrumb;

export const ProductSubstitution = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const { lineItemId } = useParams();
	const [substituteDetails, setSubstituteDetails] = useState([]);
	const { t } = useTranslation();
	const [tokenTaken, setTokenTaken] = useState(false);
	const [productImages, setProductImages] = useState({});
	const [productDescriptions, setProductDescriptions] = useState({});
	const [product] = useState(JSON.parse(localStorage.getItem('product')));

	// sets the back url
	const { state } = useLocation();

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Product Substitution';
	}, []);

	useEffect(() => {
		if (state && state.backUrl) {
			localStorage.setItem('backUrl', state.backUrl);
		}
	}, [state.backUrl]);

	if (!tokenTaken) {
		setTokenTaken(true);
		getProductImagesToken()
			.then(token => {
				sessionStorage.setItem('imageToken', JSON.stringify(token));
			})
			.catch();
	}

	const loadImage = productCode => {
		getProductImage(productCode)
			.then(res => {
				//lineItem.productCode
				if (res.images) {
					setProductImages(prev => ({
						...prev,
						[productCode]: res.images.filter(
							image => image.fullUrl.indexOf('cloudfront') > -1,
						)[0].fullUrl,
					}));
				}
				if (res.longDescription) {
					setProductDescriptions(prev => ({
						...prev,
						[productCode]: res.longDescription
							.map(item => item.attrValue)
							.join(' '),
					}));
				}
			})
			.catch(() => {
				console.log('Some images were not loaded successfully!');
			});
	};

	useEffect(() => {
		setLoading(true);
		getSubstituteProducts(lineItemId, 'FR')
			.then(response => {
				if (
					response &&
					response.productDetails &&
					response.productDetails.prodSubstList
				) {
					response.productDetails.prodSubstList.map(item =>
						loadImage(item.ProductCode),
					);
					setSubstituteDetails(response.productDetails.prodSubstList);
				}
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				message.error('Could not load substitution details. Please reload!');
			});
	}, [lineItemId]);

	return (
		<div
			className="common-page-comtainer"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, null, null, true)}>
			<TopBar />
			{localStorage.getItem('OBackUrl') === '/orders' && (
				<Row>
					<Col span={22} offset={1}>
						<Breadcrumb>
							<Item key={'bc-orders'}>
								<Link className="disabled" to={'/orders'}>
									{t('orders')}
								</Link>
							</Item>
							<Item key={'bc-products'}>
								<Link className="disabled" to={localStorage.getItem('backUrl')}>
									{t('Products')}
								</Link>
							</Item>
							<Item key={'bc-products'}>
								<Link to={window.location.pathname}>
									{t('productSubstitution')}
								</Link>
							</Item>
						</Breadcrumb>
					</Col>
				</Row>
			)}
			{localStorage.getItem('OBackUrl').indexOf('/account') > -1 && (
				<Row>
					<Col span={22} offset={1}>
						<Breadcrumb>
							{localStorage.getItem('AOBackUrl') === '/orders' && (
								<Item key={'bc-orders'}>
									<Link className="disabled" to={'/orders'}>
										{t('orders')}
									</Link>
								</Item>
							)}
							{localStorage.getItem('AOBackUrl') !== '/orders' && (
								<Item key={'bc-accounts'}>
									<Link className="disabled" to={'/accounts'}>
										{t('accounts')}
									</Link>
								</Item>
							)}
							<Item key={'bc-aorders'}>
								<Link
									className="disabled"
									to={localStorage.getItem('OBackUrl')}>
									{t('account')}
								</Link>
							</Item>
							<Item key={'bc-aorders'}>
								<Link
									className="disabled"
									to={localStorage.getItem('OBackUrl')}>
									{t('Products')}
								</Link>
							</Item>
							<Item key={'bc-products'}>
								<Link to={window.location.pathname}>
									{t('productSubstitution')}
								</Link>
							</Item>
						</Breadcrumb>
					</Col>
				</Row>
			)}
			<Row
				justify="space-around"
				align="middle"
				className="product-substitution">
				<Col span={23} className="col-container">
					<Row justify="space-around" align="middle">
						<LineItem
							key={'line-item'}
							entry={product.entry}
							isLoading={false}
							imageUrl={product.imageUrl}
							orderId={product.orderId}
							showProdSub={false}
						/>
					</Row>
					<Row justify="space-around" align="middle">
						<Col span={24}>
							<Typography.Title className="sub-title" level={5}>
								{t('productSubstitution')}({substituteDetails.length})
							</Typography.Title>
							<Typography.Title className="sub-title-description" level={5}>
								{t('clickProduct')}
							</Typography.Title>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="substitution-container">
						{substituteDetails &&
							substituteDetails.length > 0 &&
							substituteDetails.map((item, index) => (
								<Col key={'substitution-item-' + index} span={24}>
									<a href={'tel:' + getFsrCustomerCareNumber()}>
										<Card className="subs-item" key={'sub-item-' + index}>
											<Row>
												<Col span={8}>
													<Image
														className="line-item-img"
														src={
															productImages[item.ProductCode]
																? productImages[item.ProductCode]
																: '/media/missing-product-515x515.jpg'
														}
													/>
												</Col>
												<Col span={15} offset={1}>
													<Row>
														<Col span={24}>
															<Typography.Title className="name">
																{productDescriptions[item.ProductCode]
																	? productDescriptions[item.ProductCode]
																	: ''}
															</Typography.Title>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<Typography.Title className="code">
																{item.ProductCode}
															</Typography.Title>
														</Col>
													</Row>
													<Row>
														{/* <Col span={14}><Typography.Text className="code">$91.56  {t('each')}</Typography.Text></Col> */}
														<Col span={10}>
															<Typography.Text className="code">
																{parseInt(item.matchPrcnt)}% {t('Match')}
															</Typography.Text>
														</Col>
													</Row>
												</Col>
											</Row>
										</Card>
									</a>
								</Col>
							))}
						{/* {
                            loading && <Spin indicator={<Image src="/icons/loading.gif" />} />
                        } */}
						{loading && (
							<CircularProgressBar
								percentage={10}
								status="exception"
								width={50}
							/>
						)}
					</Row>
				</Col>
			</Row>
			<Footer />
		</div>
	);
};
