import { Row, Col, Typography, Image, Button } from 'antd';
import './../styles/HelpOrderStatus.scss';
import { TopBar } from '../elements/TopBar';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BASE_TITLE } from '../utils/utility';

export const HelpOrderStatus = () => {
	const { push } = useHistory();
	const [pagex] = useState(0);
	const [currentPageX] = useState(0);
	const { t } = useTranslation();
	const history = useHistory();
	const [lang] = useState(localStorage.getItem('lang'));
	const { pathname } = useLocation();

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Help - Order Status';
	}, []);

	useEffect(() => {
		localStorage.setItem('backUrl', '/help');
	}, [pathname]);

	return (
		<div
			className="common-page-container"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, 'help-order-status-work', 'help')}>
			<TopBar backUrl={'/help'} />
			<Row
				justify="space-around"
				align="middle"
				className="help-order-status"
				style={{ left: currentPageX - pagex + 'px' }}>
				<Col span={23} className="col-container">
					<Row>
						<Col span={24}>
							{lang === 'en' && (
								<Image
									className="help-image"
									src="/media/help-image1.png"
									preview={false}
								/>
							)}
							{lang === 'fr' && (
								<Image
									className="help-image"
									src="/media/svg/help_order_status.svg"
									preview={false}
								/>
							)}
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="order-status-title">
								{t('orderStatuses')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Paragraph
								level={5}
								className="order-status-description-1">
								{t('statuses')}
							</Typography.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Paragraph
								level={5}
								className="order-status-description-2">
								{t('letSeeHowThisWork')}
							</Typography.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col span={4} offset={10}>
							<div className="active-bullet bullet"></div>
							<div className="inactive-bullet bullet"></div>
							<div className="inactive-bullet bullet"></div>
						</Col>
					</Row>
					<Row>
						<Col span={11}>
							<Button
								className="common-deactive-button"
								onClick={() => {
									push('/help');
								}}>
								{t('backToSupport')}
							</Button>
						</Col>
						<Col span={11} offset={2}>
							<Button
								className="common-active-button"
								onClick={() => {
									localStorage.setItem('backUrl', '/help-order-status');
									push('/help-order-status-work');
								}}>
								{t('next')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
