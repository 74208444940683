import { Button, Col, Image, Row } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import '../styles/Footer.scss';

export const Footer = () => {
	const { pathname } = useLocation();
	const { push } = useHistory();
	return (
		<Row className="footer" align="middle" justify="space-around">
			<Col span={24}>
				<Button
					icon={
						<Image
							preview={false}
							src={
								pathname.match(/^\/dashboard/g)
									? '/icons/navigation-home-active.png'
									: '/icons/navigation-home.png'
							}
							onClick={() => {
								push('/dashboard');
							}}
						/>
					}
				/>
				<Button
					icon={
						<Image
							preview={false}
							src={
								pathname.match(/^\/orders/g) ||
								pathname.indexOf('product-substitution') > -1
									? '/icons/navigation-cart-active.png'
									: '/icons/navigation-cart.png'
							}
						/>
					}
					onClick={() => {
						push('/orders');
					}}
				/>
				<Button
					icon={
						<Image
							preview={false}
							src={
								pathname.match(/^\/account\/[a-zA-Z1-9]*\/orders/g) ||
								pathname.match(/^\/accounts/g) ||
								pathname.match(
									/^\/account\/[a-zA-Z1-9]*\/order\/[a-zA-Z1-9]*\/line-items/g,
								)
									? '/icons/navigation-options-active.png'
									: '/icons/navigation-options.png'
							}
						/>
					}
					onClick={() => {
						push('/accounts');
					}}
				/>
				<Button
					icon={
						<Image
							preview={false}
							src={
								pathname.match(/^\/profile/g)
									? '/icons/navigation-profile-active.png'
									: '/icons/navigation-profile.png'
							}
						/>
					}
					onClick={() => {
						push('/profile');
					}}
				/>
				<Button
					icon={
						<Image
							preview={false}
							src={
								pathname.match(/^\/help/g)
									? '/icons/navigation-help-active.png'
									: '/icons/navigation-help.png'
							}
						/>
					}
					onClick={() => {
						push('/help');
					}}
				/>
			</Col>
		</Row>
	);
};
