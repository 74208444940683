import { useState, useEffect, useRef } from 'react';
import { Input, Empty } from 'antd';
import '../styles/account_list.scss';
import { AccountListService } from '../services/AccountListService';
import { Link } from 'react-router-dom';
import { Footer } from '../elements/Footer';
import { TopBar } from '../elements/TopBar';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../elements/CircularProgressBar';
import AccountListItem from '../elements/AccountListItem';
import { useHistory } from 'react-router-dom';
import { BASE_TITLE } from '../utils/utility';

// Screen for account list
// if list is not emty returns list
// if is loading return loading icon with the list
// if not loading and no data, return no data
const canListAccounts = (loading, list, avgResponseTime) => {
	return (
		<>
			{list.length > 0 &&
				list
					.map((account, index) => {
						// sets the key for account list
						account.key = 'link-' + index.toString();
						return account;
					})
					.map(account => (
						<Link
							to={{
								pathname: `/account/${account.cac_account_number}/orders`,
								state: {
									backUrl: window.location.pathname,
								},
							}}
							key={account.key}>
							<AccountListItem
								key={account.cac_account_number}
								item={account}
							/>
						</Link>
					))}
			{loading > 0 && (
				<div className="loading-container">
					<CircularProgressBar
						percentage={avgResponseTime}
						status="exception"
						width={50}
					/>
				</div>
			)}
			{loading < 1 && list.length === 0 && <Empty />}
		</>
	);
};

// add red class based on the actively showing tab to highlight the tab in red
const getHighlightedTabClass = onlyUpdates => (onlyUpdates ? 'red' : '');

// Sets query parameters
export const getSearchParams = (onlyUpdates, searchText, limit, pageNumber) => {
	const searchParams = {
		updatedAccounts: onlyUpdates ? 'true' : 'false',
		limit,
		offset: pageNumber.toString(),
	};

	// search text is optional
	if (searchText !== '') {
		searchParams.searchParam = searchText;
	}
	return searchParams;
};

// check whether can scroll when scoll to the bottom of the accounts list container
const canPaginate = (bottom, size, loading, pageNumber, limit) =>
	bottom && size > 0 && loading <= 0 && size === (pageNumber + 1) * limit;

// check if is in process of API execution
const checkIsLoading = loading => (loading > 0 ? loading - 1 : 0);

// Here contacts account list for the newly obtained data
const concatAccountList = (reset, data, accountList) =>
	reset ? data : accountList.concat(data);
// const getDsabled = disabled => (disabled ? 'disabled' : '');

export const AccountList = ({ hasUpdates = false }) => {
	const [loading, setLoading] = useState(0);
	const [accountList, setAccountList] = useState([]);
	const [searchText, setSearchText] = useState('');

	// used to show swith bet ween only updates and all tabs
	const [onlyUpdates, setOnlyUpdates] = useState(hasUpdates);
	const accountUpdateButton = useRef('');
	const allAccountsButton = useRef('');
	const [pageNumber, setPageNumber] = useState(0);
	const limit = 10;
	const avgResponseTime = 5;

	// allow call to actual backend call only if mustSeatch true
	// Initial value is true
	const [mustSearch, setMustSearch] = useState(true);
	const [reset, setReset] = useState(true);
	const { push } = useHistory();

	useEffect(() => {
		localStorage.setItem('backUrl', window.location.pathname);
		document.title = BASE_TITLE + ' - Account Index';
	}, []);
	useEffect(() => {
		setMustSearch(true);
		setOnlyUpdates(hasUpdates);
	}, [hasUpdates]);

	// start to call load account list with parameters
	const pageSearch = () => {
		setLoading(loading + 1);
		AccountListService(
			getSearchParams(onlyUpdates, searchText, limit, pageNumber),
		)
			.then(data => {
				// not going to do the data processing if data is empty or has an error
				if (data.length === 0 || data.error || data.errorMessage) {
					setLoading(checkIsLoading(loading));
					return;
				}
				setAccountList(
					concatAccountList(reset, data.fsr_accounts, accountList),
				);
				localStorage.setItem('fsr_country', data.fsr_country);
				localStorage.setItem('fsr_name', data.fsr_name);
				localStorage.setItem('fsr_number', data.fsr_number);
				localStorage.setItem('fsr_email', data.email);
				setLoading(checkIsLoading(loading));
			})
			.catch(() => {
				setLoading(checkIsLoading(loading));
			});
	};

	useEffect(() => {
		if (mustSearch) {
			setMustSearch(false);
			pageSearch();
		}
	}, [onlyUpdates, pageNumber, searchText, mustSearch]);
	// watch for onlyUpdates, pageNumber, searchText, mustSearch all params but trigger search is only for mustSearch is true

	// initiate internationalization
	const { t } = useTranslation();

	// trigger loading data by updating parameters
	const doSearch = () => {
		document.activeElement.blur();
		setAccountList([]);
		setPageNumber(0);
		setMustSearch(true);
		window.focus();
		document.activeElement.blur();
	};

	// Switch tabs data between onlyUpdtaes and all data
	const switchTabData = updates => {
		push(updates ? '/accounts/updates' : '/accounts');
	};

	return (
		<div>
			<div style={{ background: '#FFF' }}>
				<div className="account__list__account__index">
					<div className="topbar">
						<TopBar backVisibility={false} />
					</div>

					<div className="buttons-tab">
						<button
							className={getHighlightedTabClass(onlyUpdates)}
							ref={accountUpdateButton}
							onClick={() => switchTabData(true)}>
							{t('accountUpdates')}
						</button>
						<button
							className={getHighlightedTabClass(!onlyUpdates)}
							ref={allAccountsButton}
							onClick={() => switchTabData(false)}>
							{t('allAccounts')}
						</button>
					</div>

					<div className="search-container">
						<Input
							className="account__list__search"
							allowClear
							prefix={
								<img
									src="/icons/search-icon.png"
									onClick={doSearch}
									style={{ cursor: 'pointer' }}
									className="search-image"
									alt="search"
								/>
							}
							placeholder={t('searchAccountName')}
							onPressEnter={doSearch}
							onChange={e => {
								setSearchText(e.target.value);
								if (e.target.value === '') {
									doSearch();
								}
							}}
						/>
					</div>

					<div
						className="account__list__container"
						onScroll={e => {
							const bottom =
								e.target.scrollHeight - e.target.scrollTop ===
								e.target.clientHeight;
							if (
								canPaginate(
									bottom,
									accountList.length,
									loading,
									pageNumber,
									limit,
								)
							) {
								setPageNumber(pageNumber + 1);
								setReset(false);
								setMustSearch(true);
							}
						}}>
						{canListAccounts(loading, accountList, avgResponseTime)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
