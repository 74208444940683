import {useEffect} from 'react';
import { Card, Col, Image, Row, Typography } from 'antd';
import { LINE_ITEM_STATU_MAP } from '../types/enums';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import { getSubstituteProducts } from '../services/ProductSubstitution';
import { getLocaleCurrencyFormat } from '../utils/localCurrencyFormat';
import { getFsrCountry } from '../utils/utility';
import { t } from 'i18next';

const enableSubstitutionLink = (entry, substitutionCount) =>
	entry.itemStatus !== 'Cancelled' &&
	entry.itemStatus !== 'In_progress' &&
	entry.itemStatus !== 'Submitted' &&
	entry.itemStatus !== 'Completed' &&
	entry.itemStatus !== 'Shipped' &&
	substitutionCount > 0 &&
	getFsrCountry() === 'FR';

const getDelivertDate = entry => {
	if (entry.itemStatus === 'Completed') {
		return (
			<Typography.Text className="line-item-info">
				{t('delivered')} :
				{' ' + entry.line_item_delivered_date
					? moment(entry.line_item_delivered_date).format('L')
					: '-'}
			</Typography.Text>
		);
	} else if (
		entry.itemStatus !== 'Cancelled' &&
		entry.itemStatus !== 'Submitted'
	) {
		return (
			<Typography.Text className="line-item-info">
				{t('estDelivery')} :
				{' ' + entry.line_item_estimated_delivery_date
					? moment(entry.line_item_estimated_delivery_date).format('L')
					: '-'}
			</Typography.Text>
		);
	} else {
		return '';
	}
};

const LineItem = ({
	entry,
	isLoading,
	imageUrl,
	orderId,
	showProdSub = true,
}) => {
	const { push } = useHistory();
	const [substitutionCount, setSubstitutionCount] = useState(0);
	useEffect(() => {
		getSubstituteProducts(entry.productCode, getFsrCountry())
			.then(response => {
				if (
					response &&
					response.productDetails &&
					response.productDetails.prodSubstList
				) {
					setSubstitutionCount(response.productDetails.prodSubstList.length);
				}
			})
			.catch(() => {
				console.log('This product does not have substitution details!');
			});
	}, []);
	return (
		<Col span={24} key={'li-' + entry.productCode}>
			<Card className="line-item" loading={isLoading}>
				<Row>
					<Col span={7}>
						<Image
							className="line-item-img"
							src={imageUrl ? imageUrl : '/media/missing-product-515x515.jpg'}
						/>
						<Typography.Text className="line-item-info">
							{t(LINE_ITEM_STATU_MAP[entry.itemStatus])}
						</Typography.Text>
					</Col>
					<Col span={17}>
						<Row>
							<Col span={24}>
								<Typography.Title className="line-item-name">
									{entry.productName}
								</Typography.Title>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{entry.productCode}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{t('qty')}: {entry.productQuantity}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{entry.lineTotal
										? getLocaleCurrencyFormat(
												entry.currencyCode,
												entry.lineTotal,
										  )
										: 0}{' '}
									{t('total')}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>{getDelivertDate(entry)}</Col>
						</Row>
						<Row>
							<Col span={24}>
								{enableSubstitutionLink(entry, substitutionCount) &&
								showProdSub ? (
									<>
										<Typography.Text
											className="subs-link"
											onClick={() => {
												localStorage.setItem(
													'product',
													JSON.stringify({
														entry: entry,
														isLoading: false,
														imageUrl: imageUrl,
														orderId: orderId,
													}),
												);
												push(
													`/order/${orderId}/product-substitution/${entry.productCode}`,
													{ backUrl: location.pathname },
												);
											}}>
											{t('viewProdSub')}
										</Typography.Text>
										<Typography.Text className="line-item-info">
											({substitutionCount})
										</Typography.Text>
									</>
								) : (
									<Typography.Text className="line-item-info">
										{t('noProductSubstitution')}
									</Typography.Text>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
		</Col>
	);
};
export default LineItem;
