import { Row, Col, Typography, Image, Input, Empty, Breadcrumb } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import './../styles/OrderHead.scss';
import { ORDER_API_STATUS_TEXT } from '../types/enums';
import { Footer } from '../elements/Footer';
import { TopBar } from '../elements/TopBar';
import {
	getProductImage,
	getProductImagesToken,
} from '../services/ProductImages';
import CircularProgressBar from '../elements/CircularProgressBar';
import { Order } from '../elements/Order';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import { getOrders } from '../services/accountOrders';
import LineFilterButton from '../elements/LineFilterButton';
import LineItem from '../elements/LineItem';
import { t } from 'i18next';
import { BASE_TITLE } from '../utils/utility';

// returns the search params related to a single order
const getSearchParams = (activeFilter, searchText, orderId, pageNumber) => {
	const searchParams = {
		offset: pageNumber.toString(),
		limit: '10',
		orderLineStatus: activeFilter,
		oId: orderId,
		orderLines: 'true',
	};

	// if the seatch text is empty remove the seatchParam from the search query
	if (searchText !== '') {
		searchParams.searchParam = searchText;
	}

	// if the seatching order line item status is ALL just remove filtering from lineItemStatus
	if (searchParams.orderLineStatus === ORDER_API_STATUS_TEXT.ALL) {
		delete searchParams.orderLineStatus;
	}
	return searchParams;
};

// check if the API iresult contains line items before setting
const canSetLineItems = (items, setLineItems, resetAccountList) => {
	if (items.length > 0) {
		setLineItems(prev =>
			resetAccountList
				? [].concat.apply([], items)
				: prev.concat([].concat.apply([], items)),
		);
	} else if (resetAccountList) {
		setLineItems([]);
	}
};

// check if user scorlled to the bottom of the list to load the next set of the list from API
const canSetPage = (e, size, loading, filter, meta) => {
	const bottom =
		e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
	if (filter) {
		return bottom && size > 0 && loading <= 0 && size < filter;
	} else {
		return bottom && size > 0 && loading <= 0 && size < meta['ALL'];
	}
};

// check if active API call is happening and the screen is not initialized to set all line items status to loading
const isLineItemLoading = (loading, init) => loading && !init;

// return the circular loading icon if the screen is in loading status
const getLoading = loading =>
	loading && (
		<CircularProgressBar percentage={10} status="exception" width={50} />
	);

// if API returned line items are empty or has an error, returns from further processing
const needReturnLineItems = (size, error) => size === 0 || error;

// returns empty icon if the API is not loading anythig and length of the line items are 0
const getEmpty = (length, loading) => length === 0 && !loading && <Empty />;

// call product image API to load image from product code
const loadImage = (productCode, setProductImages) => {
	getProductImage(productCode).then(res => {
		if (res.images) {
			const prodImage = res.images.filter(
				image => image.fullUrl.indexOf('cloudfront') > -1,
			);
			if (prodImage.length > 0) {
				setProductImages(prev => ({
					...prev,
					[productCode]: prodImage[0].fullUrl,
				}));
			}
		}
	});
};

// sets and returns order headers screen
export const OrderHead = () => {
	const history = useHistory();

	// set to true when active API call is happening
	const [loading, setLoading] = useState(true);

	// true if the screen is initiated
	const [init, setInit] = useState(false);

	// order id from url param
	var { orderId, cac } = useParams();

	// can set the array of line items
	var [lineItems, setLineItems] = useState([]);

	// can set the order details on the top of the screen
	var [orderDetails, setOrderDetails] = useState({});

	// can set active filter of the screen
	var [activeFilter, setActiveFilter] = useState(ORDER_API_STATUS_TEXT.ALL);

	// can set the search text which can be line item name
	var [searchText, setSearchText] = useState('');

	// can set current page of data
	var [page, setPage] = useState('0');

	// can set count of line items from each filter
	var [meta, setMeta] = useState({
		ALL: 0,
		[ORDER_API_STATUS_TEXT.BACKORDERED]: 0,
		[ORDER_API_STATUS_TEXT.CANCELLED]: 0,
		[ORDER_API_STATUS_TEXT.COMPLETE]: 0,
		[ORDER_API_STATUS_TEXT.IN_PROGRESS]: 0,
		[ORDER_API_STATUS_TEXT.SHIPPED]: 0,
		[ORDER_API_STATUS_TEXT.SUBMITTED]: 0,
	});

	// can enable if the api call is executable or not
	const [mustSearch, setMustSearch] = useState(true);

	// can set whether data should be reset or not
	const [resetAccountList, setResetAccountList] = useState(true);

	// sets the back url
	const { state } = useLocation();

	// sets active filter, if current filter is same as active filter it returns
	var setFilter = filterName => {
		if (filterName === activeFilter) {
			return;
		}
		setPage('0');
		setLineItems([]);
		setActiveFilter(filterName);
		setResetAccountList(true);
		setMustSearch(true);
	};

	// can set the product images for all the line items liaded, if exists
	var [productImages, setProductImages] = useState({});

	// can set product image token
	var [tokenTaken, setTokenTaken] = useState(false);

	// set return url
	const [backUrl, setBackUrl] = useState(
		state !== undefined ? state.backUrl : localStorage.getItem('backUrl'),
	);

	const { Item } = Breadcrumb;

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Order Head';
	}, []);

	let bUrl = state ? state.backUrl : '';
	useEffect(() => {
		if (state && state.backUrl) {
			setBackUrl(state.backUrl);
			localStorage.setItem('backUrl', state.backUrl);
			localStorage.setItem('OBackUrl', state.backUrl);
		} else {
			localStorage.setItem('backUrl', localStorage.getItem('OBackUrl'));
		}
	}, [bUrl]);

	// if the product image is not set, then obtain the token from the image api
	if (!tokenTaken) {
		setTokenTaken(true);
		getProductImagesToken()
			.then(token => {
				sessionStorage.setItem('imageToken', JSON.stringify(token));
			})
			.catch();
	}

	// api call wrapper function to load orders
	const loadOrders = () => {
		setLoading(true);

		// execute actiual service call with validated params
		getOrders(getSearchParams(activeFilter, searchText, orderId, page))
			.then(response => {
				// if nodata or error in the api, returns without further processing
				if (needReturnLineItems(response.length, response.error)) {
					return;
				}
				const resmeta = response.shift().order_lines_summary;
				// sets the count of each filter as meta
				setMeta(resmeta);
				var items = [];
				if (response[0]) {
					const order = response[0];
					if (!init) {
						// sets order details only at the initialize phase of the screen
						setOrderDetails({
							orderNum: order.order_number.split('-')[0],
							accountName: order.shitpto_cus_name,
							accountId: order.cus_shipto_num,
							poNumber: order.po_number,
							requestedDate: order.order_placemet_date,
							totalAmount: order.total_price,
							currencyCode: order.currency,
							updatedDate: order.order_last_update,
							prcntComplete: order.ordr_prcnt_complete,
							orderStatus: order.order_status,
							orderRecordId: order.order_number,
						});
					}
					setInit(true);

					// return line items with correct keys
					items = order.orderItems.map(lineItem => {
						loadImage(lineItem.line_item_product_code, setProductImages);
						return {
							productCode: lineItem.line_item_product_code,
							productName: lineItem.line_item_product_desc,
							productQuantity: lineItem.line_item_pro_quantity,
							lineTotal: lineItem.product_line_cost,
							currencyCode: lineItem.currency,
							itemStatus: lineItem.o_line_item_status,
						};
					});
				}

				// check and set line items
				canSetLineItems(items, setLineItems, resetAccountList);

				// set false the loading state since api execution is done
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				canSetLineItems([], setLineItems, true);
			});
	};

	useEffect(() => {
		// api call execute only if mustSearch is true
		if (mustSearch) {
			loadOrders();
		}
	}, [activeFilter, page, searchText]);

	// search order line item
	const initSearch = () => {
		document.activeElement.blur();
		setLineItems([]);
		setPage('0');
		setResetAccountList(true);
		setMustSearch(true);

		// sets cursor focus out side of search after seatch is triggered
		window.focus();
		document.activeElement.blur();
	};

	// check if element is child of filtering
	const isInFiltering = node => {
		while (node) {
			if (node.className && node.className.indexOf('filter') > -1) {
				return true;
			}
			node = node.parentNode;
		}
		return false;
	};

	return (
		<div
			className="common-page-comtainer"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={e => {
				if (!isInFiltering(e.target)) {
					touchEnd(history, null, null, true)();
				}
			}}>
			<TopBar backUrl={backUrl} />
			{backUrl === '/orders' && (
				<Row>
					<Col span={22} offset={1}>
						<Breadcrumb>
							<Item key={'bc-orders'}>
								<Link className="disabled" to={backUrl}>
									{t('orders')}
								</Link>
							</Item>
							<Item key={'bc-products'}>
								<Link to={window.location.pathname}>{t('Products')}</Link>
							</Item>
						</Breadcrumb>
					</Col>
				</Row>
			)}
			{backUrl.indexOf('/account') > -1 && (
				<Row>
					<Col span={22} offset={1}>
						<Breadcrumb>
							{localStorage.getItem('AOBackUrl') === '/orders' && (
								<Item key={'bc-orders'}>
									<Link className="disabled" to={'/orders'}>
										{t('orders')}
									</Link>
								</Item>
							)}
							{localStorage.getItem('AOBackUrl') !== '/orders' && (
								<Item key={'bc-accounts'}>
									<Link className="disabled" to={'/accounts'}>
										{t('accounts')}
									</Link>
								</Item>
							)}
							<Item key={'bc-aorders'}>
								<Link className="disabled" to={backUrl}>
									{t('account')}
								</Link>
							</Item>
							<Item key={'bc-products'}>
								<Link to={window.location.pathname}>{t('Products')}</Link>
							</Item>
						</Breadcrumb>
					</Col>
				</Row>
			)}
			<Row justify="space-around" align="middle" className="order-head">
				<Col span={23} className="col-container">
					<Row justify="space-around" align="middle">
						<Order
							order={orderDetails}
							loading={isLineItemLoading(loading, init)}
							withNavigation={false}
							orderHead={true}
							accountId={cac}
						/>
					</Row>
					<Row>
						<Col span={24}>
							<Input
								placeholder={t('searchAccountName')}
								onChange={event => {
									setSearchText(event.target.value);
									if (event.target.value === '') {
										initSearch();
									}
								}}
								className="search"
								prefix={
									<Image
										preview={false}
										src="/icons/search-icon.png"
										onClick={initSearch}
									/>
								}
								onPressEnter={initSearch}
								allowClear
							/>
						</Col>
					</Row>
					<Row align="middle">
						<Col span={24} className="filter-container">
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.ALL);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.ALL}
								meta={meta}
							/>
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.BACKORDERED);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.BACKORDERED}
								meta={meta}
							/>
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.CANCELLED);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.CANCELLED}
								meta={meta}
							/>
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.IN_PROGRESS);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.IN_PROGRESS}
								meta={meta}
							/>
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.SHIPPED);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.SHIPPED}
								meta={meta}
							/>
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.SUBMITTED);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.SUBMITTED}
								meta={meta}
							/>
							<LineFilterButton
								onClick={() => {
									setFilter(ORDER_API_STATUS_TEXT.COMPLETE);
								}}
								activeFilter={activeFilter}
								filter={ORDER_API_STATUS_TEXT.COMPLETE}
								meta={meta}
							/>
						</Col>
					</Row>
					<Row justify="space-around" align="middle">
						<Col span={24}>
							<Typography.Title className="filter-name-text" level={5}>
								{t(activeFilter)}
							</Typography.Title>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="li-container"
						onScroll={e => {
							if (
								canSetPage(
									e,
									lineItems.length,
									loading,
									meta[activeFilter],
									meta,
								)
							) {
								setResetAccountList(false);
								setMustSearch(true);
								setPage((Number(page) + 1).toString());
							}
						}}>
						{lineItems.map((entry, index) => {
							return (
								<LineItem
									key={'line-item-' + index.toString()}
									entry={entry}
									isLoading={isLineItemLoading(loading, init)}
									imageUrl={productImages[entry.productCode]}
									orderId={orderId}
								/>
							);
						})}
						{getEmpty(lineItems.length, loading)}
						{/* {loading && <Spin indicator={<Image src="/icons/loading.gif" />} />} */}
						{getLoading(loading)}
					</Row>
				</Col>
			</Row>
			<Footer />
		</div>
	);
};
