import { TopBar } from '../elements/TopBar';
import '../styles/p_profile.scss';
import '../styles/info.scss';
import { Footer } from '../elements/Footer';
import { useTranslation } from 'react-i18next';
import { BASE_TITLE, getFsrEmail, getFsrName, getFsrNumber } from '../utils/utility';
import { Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import { useEffect } from 'react';

function Profile() {
	const { t } = useTranslation();
	// sets page title 
	useEffect(() => {
		document.title = BASE_TITLE + " - Profile";
	}, []);
	return (
		<div>
			<div className="profile">
				<div className="topbar">
					<TopBar backVisibility={false} />
				</div>
				<div className="profile-info">
					<div className="name">{getFsrName()}</div>
					<div className="member-group">{t('placeOrder')}</div>
					<div className="info-list">
						<div className="info-title">{t('contactInformation')}</div>
						<div className="info-container">
							<div className="desc">
								<div className="desc-title">{t('email')}</div>
								<div className="desc-value">{getFsrEmail()}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('notificationEmail')}</div>
								<div className="desc-value">{getFsrEmail()}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('phoneNo')}</div>
								<div className="desc-value">{getFsrNumber()}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('mobileNo')}</div>
								<div className="desc-value">{getFsrNumber()}</div>
								<div className="separator" />
							</div>
						</div>
					</div>
					{
						/* <div className="info-list">
						<div className="info-title">{t('addresses')}</div>
						<div className="info-container">
							<div className="desc">
								<div className="desc-title">{t('address1')}</div>
								<div className="desc-value">{'800 BISTERFIELD RD'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('address2')}</div>
								<div className="desc-value">{'800 BISTERFIELD RD'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('city')}</div>
								<div className="desc-value">{'ELK GROVE VILLAGE'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('country')}</div>
								<div className="desc-value">{'United States'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('state')}</div>
								<div className="desc-value">{'NY'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('zipCode')}</div>
								<div className="desc-value">{'60007'}</div>
								<div className="separator" />
							</div>
						</div>
					</div>
					<div className="info-list">
						<div className="info-title">{t('WorkInformation')}</div>
						<div className="info-container">
							<div className="desc">
								<div className="desc-title">{t('orgName')}</div>
								<div className="desc-value">{'Cognizant'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('department')}</div>
								<div className="desc-value">{'IT'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('supervisorName')}</div>
								<div className="desc-value">{'Rohit Sharma'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('supervisorPhoneNo')}</div>
								<div className="desc-value">{'+1 (516) 123-316'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('supervisorEmail')}</div>
								<div className="desc-value">{'rsharma@its.jnj.com'}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('hospitalNotes')}</div>
								<div className="desc-value">{'Dispay notes from hospital'}</div>
								<div className="separator" />
							</div>
						</div>
					</div> */
						<div className="info-list">
							<div className="info-title">{t('preferredLanguage')}</div>
							<Select
								defaultValue={localStorage.getItem('lang')}
								style={{ width: 120 }}
								onChange={val => {
									localStorage.setItem('lang', val);
									window.location.reload();
								}}>
								<Option value="en">English</Option>
								<Option value="fr">French</Option>
							</Select>
						</div>
					}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Profile;
